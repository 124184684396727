import React from 'react'

function MegaMenu() {
    return (
    
            


            <div className="banner-area">
        <div className="container-fluid">

            <div className="row">

                <div className="col-12">

                    <nav className="navbar navbar-expand-xl navbar-dark bg-dark rounded">
                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbar" aria-controls="navbars"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbar">
                            <ul className="navbar-nav mx-auto">





                                <li className="nav-item">
                                    <a className="nav-link" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">About</a>
                                </li>
                                <li className="nav-item dropdown menu-area">
                                    <a className="nav-link dropdown-toggle" href="#" id="mega-one"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Imprimerie

                                    </a>
                                    <div className="dropdown-menu mega-area" aria-labelledby="mega-one">
                                       <div className="row">


                                           <div className="col-sm-6 col-lg-3">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Pub
                                               </h5>
                                                   </a>
                                                <a href="" className="dropdown-item">
                                                    <i className="fas fa-asterisk"></i>
                                                    Carte de visite économique			</a>
                                                <a href="" className="dropdown-item"> <i className="fas fa-asterisk"></i>Flyers        </a>

                                                   
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Dépliant            </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Brochure  </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Affiche           </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Autocollant                 </a>


                                              <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Carterie
                                               </h5>


</a>


                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Carte de visite 				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Cartes postales                </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Carte d'invitation           </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Cartes de voeux          </a>
                                           </div>



                                           <div className="col-sm-6 col-lg-3">

                                               <a href="" className="dropdown-item">
                                                  <h5>
                                                   <i className="fas fa-circle"></i>Office
                                               </h5>
                                                   </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Calendrier publicitaire 2022 				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Chemises à rabats              </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Enveloppes				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Tampons             </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Blocs-notes 				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Sous-mains             </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Carnets autocopiants				</a>

                                           </div>



                                           
                                           
                                           
                                            

                                            
                                            
                                            



                                           <div className="col-sm-6 col-lg-3">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Packaging
                                               </h5>

                                                
                                               
                                                
</a>
                                                    <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Etiquette PLANCHES & ROULEAUX	    </a>
                                                    <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Boite d'expédition          </a>
                                                    <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Boite personnalisée              </a>


                                           </div>

                                           <div className="col-sm-6 col-lg-3">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Image
                                               </h5>
                                                   </a>

                                           </div>
                                       </div>

                                    </div>
                                </li>


                                <li className="nav-item dropdown menu-area">
                                    <a className="nav-link dropdown-toggle" href="#" id="mega-one"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Signalétique

                                    </a>

                                    <div className="dropdown-menu mega-area" aria-labelledby="mega-one">
                                       <div className="row">


                                           <div className="col-sm-6 col-lg-3">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Supports Souples
                                               </h5>

</a>











                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Bâche	</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Adhésif vinyle        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>  Drapeaux     </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Fond de scène        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Stickers voiture        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Impression sur tissu        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Poster mural        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Habillage Véhicule        </a>
                                                


                                           </div>


                                           <div className="col-sm-6 col-lg-3">
                                           <a href="" className="dropdown-item">
                                                   <h5>
                                                   <i className="fas fa-circle"></i>Enseigne publicitaire
                                               </h5>
</a>







 


                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Panneau PVC 			</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Allucobond             </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Plaque en plexiglas               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Plaque professionnelle               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Caisson lumineuse               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Panneau Immobilier               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Panneau signalétique              </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Panneau forex               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Panneau de chantier              </a>







                                           </div>






                                           <div className="col-sm-6 col-lg-3">
                                        <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>PLV
                                               </h5>
</a>

                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Roll up			</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Beach flag				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>XBanner			</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Photocalls, stands, comptoirs 				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Mur d'image				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Totem tissu				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Kakemono			</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Enseignes suspendues				</a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Stop trottoir				</a>















                                           </div>










                                     <div className="col-sm-6 col-lg-3">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Image
                                               </h5>
                                                   </a>

                                           </div>
                                       </div>

                                    </div>
                                </li>


                                <li className="nav-item dropdown menu-area">
                                    <a className="nav-link dropdown-toggle" href="#" id="mega-one"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Objets Pub

                                    </a>

                                    <div className="dropdown-menu mega-area" aria-labelledby="mega-one">
                                       <div className="row">


                                           <div className="col-sm-6 col-lg-3">

                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Mug personnalisé                               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Mobilier publicitaire                               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Coussin personnalisé                                  </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Serviette personnalisée        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Badge personnalisé                   </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Bracelet personnalisé                               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Porte clé                     </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Stylo personnalisé                    </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Batterie externe         </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Clé USB personnalisée                        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Tapis de souris personnalisé                             </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Coffret                            </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Trophées                             </a>
                                                
                                                
                                                
                                                
                                                
                                                
                                                






                                           </div>






                                           <div className="col-sm-6 col-lg-3">

                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Image
                                               </h5>
                                                   </a>

                                           </div>
                                       </div>

                                    </div>
                                </li>


                                <li className="nav-item dropdown menu-area">
                                    <a className="nav-link dropdown-toggle" href="#" id="mega-one"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Textiles

                                    </a>

                                    <div className="dropdown-menu mega-area" aria-labelledby="mega-one">
                                       <div className="row">


                                           <div className="col-sm-6 col-lg-2">

                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>T-shirt personnalisé                             </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Sac publicitaires                               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Polo personnalisé                               </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Sweat personnalisé        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Vêtements professionnels        </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>casquette personnalisée         </a>
                                                <a href="" className="dropdown-item"><i className="fas fa-asterisk"></i>Bavette personnalisée       </a>

                                           </div>













                                           <div className="col-sm-6 col-lg-2">
                                               <a href="" className="dropdown-item">
                                               <h5>
                                                   <i className="fas fa-circle"></i>Image
                                               </h5>
                                                   </a>

                                           </div>
                                       </div>

                                    </div>
                                </li>

                                <li className="nav-item dropdown menu-area">
                                    <a className="nav-link dropdown-toggle" href="#" id="mega-one"
                                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Tableaux Deco

                                    </a>


                                </li>




                                <li className="nav-item">
                                    <a className="nav-link" href="#">Contact</a>
                                </li>

                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>


    </div>


       
    )
}

export default MegaMenu
