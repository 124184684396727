import React from 'react'
import logo from '../img/unicprod_white.png'

function Logo() {
    return (
        <div className="row">
       
        <div className="col-sm-12">
            <div>
                <a className="navbar-brand" href="http://unicprod.ma"><img className="logo" src= {logo}/></a>
            </div>
        </div>

    </div>
    )
}

export default Logo
