import React from 'react'
// import '../up.css'

function SearchList() {
    return (
        <div className="row">
      
        <div className="col-sm-4">

        </div>
        <div className="col-sm-4">
            <div className="input-group">

                <select className="form-control" id="sel1">
                    <option> All categories</option>
                    <option>Categorie 1</option>
                    <option>Categorie 2</option>
                    <option>Categorie 3</option>
                    <option>Categorie 4</option>
                </select>
                <input type="text" className="form-control" placeholder="Key words here ..."/>
                <div className="input-group-append">
                    <button className="btn btn-secondary up_btn_search" type="button">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>

        <div className="col-sm-4">
            <div className="usercart">

                <i className="far fa-user"></i>
                <a href="#"> Login</a>
                <span>or </span>
                <a href="#">register </a>
                <span>|</span>
                <a href="#"> <i className="fas fa-shopping-basket"></i> </a>
            </div>
        </div>
        
    </div>
    )
}

export default SearchList
