import React from "react";
// import slide from '../img/111.png'
//import slide1 from "../img/111.png";
import slide2 from "../img/2.png";
import slide3 from "../img/3.png";
import slide4 from "../img/4.png";
import slide5 from "../img/5.png";
import slide6 from "../img/6.png";
import slide7 from "../img/7.png";
import slide8 from "../img/8.png";
import slide9 from "../img/9.png";
import slide10 from "../img/10.png";
// import slide from '../img/slide1.png'

function SlidesHome() {
  return (
    <div className="py-3">
      <div
        id="demo"
        className="carousel slide"
        data-pause="false"
        data-ride="carousel"
      >
        <ul className="carousel-indicators">
          <li data-target="#demo" data-slide-to="0" className="active"></li>
          <li data-target="#demo" data-slide-to="1"></li>
          <li data-target="#demo" data-slide-to="2"></li>
          <li data-target="#demo" data-slide-to="3"></li>
          <li data-target="#demo" data-slide-to="4"></li>
          <li data-target="#demo" data-slide-to="5"></li>
          <li data-target="#demo" data-slide-to="6"></li>
          <li data-target="#demo" data-slide-to="7"></li>
          <li data-target="#demo" data-slide-to="8"></li>
          {/*<li data-target="#demo" data-slide-to="9"></li>*/}
          {/*<li data-target="#demo" data-slide-to="10"></li>*/}
        </ul>
        <div className="carousel-inner">
          <div className="carousel-item text-center active">
            <img className="img-fluid" src={slide2} alt="Los Angeles" />
          </div>
          {/*<div className="carousel-item text-center ">*/}
          {/*  <img className="img-fluid" src={slide2} alt="Chicago" />*/}
          {/*</div>*/}
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide3} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide4} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide5} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide6} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide7} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide8} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide9} alt="Chicago" />
          </div>
          <div className="carousel-item text-center ">
            <img className="img-fluid" src={slide10} alt="Chicago" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlidesHome;
