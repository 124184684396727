//import logo from './logo.svg';


import {Container} from 'react-bootstrap'
import './up.css';
//import Header from './components/Header'
import Footer from './components/Footer'
import Pubbband from './components/Pubband'
import SearchList from './components/SearchList'
import MegaMenu from './components/MegaMenu'
import Logo from './components/Logo'
import SlidesHome from './components/SlidesHome'

//import 'foo' from '/components/foo.js'

function App() {
  return (
    <div >

    <Pubbband />
    <SearchList/>
    <Logo/>
    <MegaMenu />
    <SlidesHome />
    <Container>
      <main>
        <h1>
          WELCOME
        </h1>
      </main>
      </Container>
      <Footer />

    </div>
  );
}

export default App;
