import React from 'react';
//import '../up.css'

function Pubbband() {
  return (
    <div className="row mb-4">
      <div className="col-sm-12">
        <div className="entete">
          UNICPROD{' '}
          <span className="multicolortext"> la publicite autrement</span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm text-white text-center">
              <a href="#">
                {' '}
                <i className="fas fa-envelope"></i>
              </a>{' '}
              contact@unicprod.ma
            </div>
            <div className="col-sm text-white text-center">
              <a href="#">
                {' '}
                <i className="fas fa-phone"></i>{' '}
              </a>{' '}
              05 29 95 99 55
            </div>
            <div className="col-sm text-white text-center">
              <a href="#">
                {' '}
                <i className="fas fa-mobile-alt"></i>{' '}
              </a>{' '}
              06 61 78 84 07
            </div>
            <div className="col-sm text-white text-center">
              <a href="#">
                {' '}
                <i className="fas fa-mobile-alt"></i>{' '}
              </a>{' '}
              06 61 25 02 47
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pubbband;
